import { ArticleHeading4, ArticleParagraph2, IconClose12 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IMyHomeHomownerTrapProps {
  onClick: () => void;
  onClose: () => void;
}

export const MyHomeHomownerTrap: React.FC<IMyHomeHomownerTrapProps> = ({ onClick, onClose }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <div className={styles['title']}>
          <ArticleHeading4 color="black_100">Мой дом</ArticleHeading4>
          <ArticleParagraph2 color="black_100">
            Оценка стоимости и тренды рынка: расскажем всё, что нужно для выгодной сдачи вашего жилья и поиска нового
          </ArticleParagraph2>

          <ArticleParagraph2 color="primary_100" onClick={onClick}>
            Добавить в Мой дом
          </ArticleParagraph2>
        </div>

        <div className={styles['icon']} onClick={onClose}>
          <IconClose12 color="gray_icons_100" />
        </div>
      </div>
    </div>
  );
};
