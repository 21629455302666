import * as React from 'react';
import { useSelector } from 'react-redux';

import { CardSectionNew } from 'shared/components/CardSectionNew';
import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { DiscountMortgageBannerContainer } from 'shared/containers/DiscountMortgageBannerContainer';
import { RealtorRequestTrapContainer } from 'shared/containers/valuationTraps';
import { selectIsAgent } from 'shared/selectors';
import { selectIsMortgageBrokerBannerAvailable } from 'shared/selectors/mortgageBroker';
import { selectIsMyHomeObjectsExists } from 'shared/selectors/valuationTraps';
import { selectOfferValuationData } from 'shared/store/offerValuation';

import { MyHomeTrapContainer } from '../MyHomeTrapContainer';
import { getMyHomeBanner } from '../utils/getMyHomeTrapBanner';

export const ValuationTrapLoader: React.FC = () => {
  const { config } = useApplicationContext();
  const trapWeightFromConfig = config.get<number>('valuationMyHomeTrapWeight');
  const isDiscountMortgageBannerEnabled = config.get<boolean>('newbuildingDiscountMortgageBanner.Enabled') || false;

  const [isMyHomeTrapVisible, setIsMyHome] = React.useState<boolean>(false);

  const isAgent = useSelector(selectIsAgent);
  const isMyHomeObjectsExists = useSelector(selectIsMyHomeObjectsExists);
  const offerValuationData = useSelector(selectOfferValuationData);
  const isMortgageBrokerBannerEnabled = useSelector(selectIsMortgageBrokerBannerAvailable);

  React.useEffect(() => {
    if (offerValuationData?.priceInfo) {
      if (!isMyHomeObjectsExists && !isAgent) {
        const isMyHomeBanner = getMyHomeBanner(trapWeightFromConfig);

        setIsMyHome(isMyHomeBanner);
      }
    }
  }, [offerValuationData, isAgent, isMyHomeObjectsExists]);

  if (isMyHomeTrapVisible) {
    return (
      <CardSectionNew hideForPrint>
        <MyHomeTrapContainer />
      </CardSectionNew>
    );
  }

  if (isMortgageBrokerBannerEnabled) {
    return null;
  }

  // TODO будет удалено после 1-го июля в CD-206534
  if (isDiscountMortgageBannerEnabled) {
    return <DiscountMortgageBannerContainer />;
  }

  return (
    <CardSectionNew hideForPrint withBorders>
      <RealtorRequestTrapContainer withoutBorders={true} />
    </CardSectionNew>
  );
};
