import { ArticleHeading4, ArticleParagraph2, IconProductKey24, IconChevronRight16 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IMyHomeTrapBannerProps {
  href: string;
  onClick: () => void;
}

export const MyHomeTrapBanner: React.FC<IMyHomeTrapBannerProps> = ({ href, onClick }) => {
  return (
    <a className={styles['container']} href={href} onClick={onClick}>
      <div className={styles['wrapper']}>
        <div className={styles['key-icon']}>
          <IconProductKey24 color="black_100" />
        </div>

        <div>
          <ArticleHeading4 color="black_100">Живёте здесь? Добавьте адрес в Мой дом.</ArticleHeading4>
          <ArticleParagraph2 color="gray_icons_100">
            Сможете следить, как меняется цена вашей квартиры
          </ArticleParagraph2>
        </div>
      </div>

      <div>
        <IconChevronRight16 color="gray_icons_100" />
      </div>
    </a>
  );
};
